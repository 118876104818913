import Contenedor from "components/Contenedor";
import { Container, Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextSection from "components/TextSection";
import Icontext from "../../../../pages/components/IconText";
import Confianza from "../../../../assets/images/PURP_WEB/2.NOSOTROS/CHECK_CAFE.png";
import cer1 from "../../../../assets/images/PURP_WEB/2.NOSOTROS/CERTIFICADO.png";
import esr from "../../../../assets/images/PURP_WEB/2.NOSOTROS/DISTINTIOV_ESR.png";
import Non from "../../../../assets/images/PURP_WEB/2.NOSOTROS/NON-GMO.png";

const theme = createTheme();

theme.typography.h5 = {
  fontSize: "1rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1rem",
  },
};
theme.typography.h4 = {
  fontSize: "1rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "1rem",
  },
};
function Presentacion() {
  return (
    <Contenedor>
      <Grid container sx={{ mx: "auto" }} xs={10} md={10} lg={10} mt={18} justifyContent="center">
        {/* Grid de MISION Y VISION */}
        <Grid
          item
          container
          px={{ xs: 0, md: 0, lg: 0 }}
          xs={11}
          md={10}
          lg={4.5}
          textAlign="center"
        >
          <ThemeProvider theme={theme}>
            <Grid item px={{ xs: 0, md: 0, lg: 0 }} textAlign="left">
              <Contenedor
                position="relative"
                sx={{
                  filter: "filter: saturate(4);",
                }}
                zIndex={1}
              >
                <TextSection variant="h5" sx={{ color: "#5B524D" }} mt={{ xs: -2, lg: 2 }} mb={0}>
                  MISSION
                </TextSection>
                <TextSection variant="button" sx={{ color: "#5B524D" }} textAlign="justify">
                  Provide agricultural solutions and inputs, market grains and quality services;
                  generating trust.
                </TextSection>
              </Contenedor>
            </Grid>
            <Grid item textAlign="justify">
              <Contenedor
                position="relative"
                sx={{
                  filter: "filter: saturate(4);",
                }}
                zIndex={1}
              >
                <TextSection variant="h5" sx={{ color: "#5B524D" }} mt={2} mb={0}>
                  VISION
                </TextSection>
                <TextSection variant="button" sx={{ color: "#5B524D" }} textAlign="justify">
                  To be a world-class, solid and solvent corporation with diversified businesses,
                  which allows us to consolidate ourselves as a point of reference for our
                  professionalism and certified quality, contributing to the economic development of
                  the region.
                </TextSection>
              </Contenedor>
            </Grid>
            <Grid item px={{ xs: 0, md: 0, lg: 0 }} textAlign="left">
              <Contenedor
                position="relative"
                sx={{
                  filter: "filter: saturate(4);",
                }}
                zIndex={1}
              >
                <TextSection variant="h5" sx={{ color: "#5B524D" }} mt={{ xs: 2, lg: 2 }} mb={0}>
                  PURPOSE
                </TextSection>
                <TextSection variant="button" sx={{ color: "#5B524D" }} textAlign="justify">
                  Connecting with the land and feeding the world, creating value and trust.
                </TextSection>
              </Contenedor>
            </Grid>
          </ThemeProvider>
        </Grid>
        {/* Grid de VALORES */}
        <Grid item container xs={5} md={4} lg={4}>
          <Container>
            <Grid justifyContent="center">
              <ThemeProvider theme={theme}>
                <Grid item px={{ xs: 0, md: 0, lg: 15 }} justifyContent="center" textAlign="left">
                  <Contenedor
                    position="relative"
                    sx={{
                      filter: "filter: saturate(4);",
                    }}
                    zIndex={1}
                  >
                    <TextSection
                      variant="h5"
                      sx={{ color: "#5B524D" }}
                      mt={{ xs: 6, lg: 2 }}
                      mb={{ xs: 0, lg: 2 }}
                    >
                      VALUE
                    </TextSection>
                  </Contenedor>
                </Grid>
                <Contenedor fullWidth px={{ xs: 0, md: 0, lg: 15 }}>
                  <Grid spacing={0} justifyContent="center" mt={2} mb={-15}>
                    <Grid display="flex" item xs={12} sm={6} md={6} lg={2}>
                      <Icontext
                        color="#5B524D"
                        imagen={Confianza}
                        maxWidth="1rem"
                        variante="button"
                        Texto="Trust"
                        mt={{ xs: 0.5, lg: 0.2 }}
                        pt={{ xs: 0, lg: 2 }}
                        pb={0}
                        px={{ xs: 0, lg: 0 }}
                      />
                    </Grid>
                    <Grid display="flex" item xs={6} sm={6} md={6} lg={2.6}>
                      <Icontext
                        color="#5B524D"
                        imagen={Confianza}
                        maxWidth="1rem"
                        variante="button"
                        Texto="Innovation"
                        mt={{ xs: 0.5, lg: 0.2 }}
                        pt={{ xs: 0, lg: 2 }}
                        pb={0}
                        px={0}
                      />
                    </Grid>
                    <Grid display="flex" item xs={6} sm={6} md={6} lg={1.9}>
                      <Icontext
                        color="#5B524D"
                        imagen={Confianza}
                        maxWidth="1rem"
                        variante="button"
                        Texto="Passion"
                        mt={{ xs: 0.5, lg: 0.2 }}
                        pt={{ xs: 0, lg: 2 }}
                        pb={0}
                        px={0}
                      />
                    </Grid>
                    <Grid display="flex" item xs={6} sm={6} md={6} lg={2.5}>
                      <Icontext
                        color="#5B524D"
                        imagen={Confianza}
                        maxWidth="1rem"
                        variante="button"
                        Texto="Proactivity"
                        mt={{ xs: 0.5, lg: 0.2 }}
                        pt={{ xs: 0, lg: 2 }}
                        pb={0}
                        px={0}
                      />
                    </Grid>
                    <Grid display="flex" item xs={6} sm={6} md={6} lg={2}>
                      <Icontext
                        color="#5B524D"
                        imagen={Confianza}
                        maxWidth="1rem"
                        variante="button"
                        Texto="Responsibility"
                        mt={{ xs: 0.5, lg: 0.2 }}
                        pt={{ xs: 0, lg: 2 }}
                        pb={{ xs: 15, lg: 0 }}
                        px={0}
                      />
                    </Grid>
                  </Grid>
                </Contenedor>
              </ThemeProvider>
            </Grid>
          </Container>
        </Grid>
        {/* Grid de CERTFICADO */}
        <Grid item container xs={7} md={4} lg={3}>
          <Container>
            <ThemeProvider theme={theme}>
              <Grid item px={{ xs: 0, md: 0, lg: 0 }} textAlign="right">
                <Contenedor
                  position="relative"
                  sx={{
                    filter: "filter: saturate(4);",
                  }}
                  zIndex={1}
                >
                  <TextSection variant="h5" sx={{ color: "#5B524D" }} mt={{ xs: 6, lg: 2 }} mb={2}>
                    CERTIFICATIONS
                  </TextSection>
                  <Grid>
                    <Contenedor component="img" src={cer1} maxWidth="11rem" />
                  </Grid>
                  <Grid>
                    <Contenedor component="img" src={esr} maxWidth="10rem" />
                  </Grid>
                  <Grid>
                    <Contenedor component="img" src={Non} maxWidth="9rem" mb={6} />
                  </Grid>
                </Contenedor>
              </Grid>
            </ThemeProvider>
          </Container>
        </Grid>
      </Grid>
    </Contenedor>
  );
}
export default Presentacion;
